/* globals common, siteurl */
const feedback = (function ($) {
  const submitFeedbackForm = function () {
    const $button = $(this);
    const formContainer = $button.closest('.feedback-form-container');
    const feedbackEmail = formContainer.find('.feedback_email').val();
    const feedbackMessage = formContainer.find('.feedback_message').val();

    if (common.IsEmail(feedbackEmail) && feedbackMessage !== '') {
      $.post(
        `${siteurl}/ajax/send_feedback`,
        { feedbackEmail, feedbackMessage },
        () => {
          if ($('.help-popup-content').length > 0) {
            $.featherlight.close();
          }
          $.featherlight('#thank-you-for-feedback-popup');
        },
      );
    } else {
      if (!common.IsEmail(feedbackEmail)) {
        $('#feedback_email').next('label').show();
      } else {
        $('#feedback_email').next('label').hide();
      }
      if (feedbackMessage === '') {
        $('#feedback_message').next('label').show();
      } else {
        $('#feedback_message').next('label').hide();
      }
    }
  };
  const ready = function () {
    $('.feedback-submit-button-js').on('click', submitFeedbackForm);
  };

  return { ready };
}(jQuery));

jQuery(feedback.ready);

