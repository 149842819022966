/* globals breakpoint, siteurl */

import $ from 'jquery';
import './toggleHeaderOnScroll';
import './navTooltipsterSubmenus';
import './dropdown';

const toggleMenuButton = document.querySelector('.js-nav-toggle');
const overlayEl = document.querySelector('.js-overlay');
const galleryMobileMenu = document.querySelector('.gallery-mobile-menu');


const mobileNavButtonClicked = (buttonEl) => {
  const expanded = buttonEl.getAttribute('aria-expanded') === 'true' || false;
  buttonEl.setAttribute('aria-expanded', !expanded);
  document.documentElement.classList.toggle('u-no-scroll');
  overlayEl.classList.toggle('is-visible');
  if (galleryMobileMenu) {
    galleryMobileMenu.classList.toggle('is-disabled');
  }
};

document.addEventListener('click', (event) => {
  if (event.target.matches('.gallery-mobile-menu__filter')) {
    const expandedEl = document.querySelector('.js-nav-toggle[aria-expanded="true"]');
    if (expandedEl) {
      mobileNavButtonClicked(expandedEl);
    }
  }
  if (event.target.classList.contains('js-nav-toggle')) {
    const expandedEl = document.querySelector('.js-nav-toggle[aria-expanded="true"]');
    if (expandedEl !== null && expandedEl !== event.target) {
      mobileNavButtonClicked(expandedEl);
    }
    mobileNavButtonClicked(event.target);
  }
  if (event.target.classList.contains('js-overlay')) {
    const expandedEl = document.querySelector('.js-nav-toggle[aria-expanded="true"]');
    mobileNavButtonClicked(expandedEl);
  }
});


$(".nav-dropdown-mobile-headline").click(function() {
  let toggleElement = $(this).data('toggle');

  $("#icon-"+toggleElement).removeClass('fa-angle-down').removeClass('fa-angle-up');
  if( $("#"+toggleElement).is(":visible") )
  {
    $("#icon-"+toggleElement).addClass('fa-angle-down');
  }
  else
  {
    $("#icon-"+toggleElement).addClass('fa-angle-up');
  }

  $("#"+toggleElement).slideToggle('fast');
})

/*
const $mobileSubMenu = $('.sub-menu-mobile');
const openSubMenu = function () {
  $mobileSubMenu.removeClass('closed');
  $mobileSubMenu.addClass('open');
  $mobileSubMenu.slideDown();
};
const closeSubMenu = function () {
  $mobileSubMenu.removeClass('compact-menu');
  $mobileSubMenu.removeClass('open');
  $mobileSubMenu.addClass('closed');
};


$(window).resize(() => {
  if (breakpoint.device.name !== 'mobile') {
    $('.c-menu__search').css('display', '').removeClass('open').addClass('closed');
  }
});




$('.js-menu-item').click(function () {
  const name = $(this).data('menu-item');
  const $el = $(`.${name}`);
  if ($el.hasClass('closed')) {
    $el.slideDown({
      complete: () => {
        $($el).addClass('open').removeClass('closed');
      },
    });
  }
  if ($el.hasClass('open')) {
    $el.slideUp({
      complete: () => {
        $($el).removeClass('open').addClass('closed');
      },
    });
  }
});
*/
