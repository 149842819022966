const device = {};
device.refreshValue = function () {
  const bodyStyles = getComputedStyle(document.body);
  /**
   * Values from getPropertyValue() have a leading space, that's why we trim it.
   * Maybe we need an alternative solution
   */
  device.name = bodyStyles.getPropertyValue('--device').trim();
};

window.addEventListener('resize', () => {
  // TODO: this should be debounced
  device.refreshValue();
});
device.refreshValue();

export { device };
