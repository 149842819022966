/* global siteurl */

import 'tooltipster';

$('.my-account').tooltipster({
  contentAsHTML: true,
  distance: 15,
  interactive: true,
  side: ['bottom'],
  theme: 'tooltipster-borderless',
  trigger: 'hover',
});

$('.wishlist-link').tooltipster({
  animation: 'fade',
  content: '<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>',
  contentAsHTML: true,
  distance: 15,
  interactive: true,
  side: ['bottom'],
  theme: 'tooltipster-noir',
  trigger: 'hover',
  updateAnimation: 'fade',
  functionBefore(instance, helper) {
    const $origin = $(helper.origin);
    if ($origin.data('loaded') !== true) {
      $.getJSON(
        `${siteurl}ajax/get_last_favourites`,
        (data) => {
          instance.content(data.view);
          $origin.data('loaded', true);
        },
      );
    }
  },
});
