const header = document.querySelector('.c-main-header');
const gallerySubHeader = document.querySelector('.gallery-top-filters');
const detachPoint = 600;
const headerOffset = 600;
const toggleOffset = 5;
let currentScrollPosition = 0;
let previousScrollPosition = 0;
let headerHeight;
let isMobile = false;
const $window = $(window);

const onResize = function () {
  isMobile = $window.width() < 780;
};
$window.resize(onResize);
onResize();

if (header !== null) {
  const topOfPage = () => (currentScrollPosition <= 0);
  const bottomOfPage = () => (window.innerHeight + currentScrollPosition) >= document.body.offsetHeight;

  /*


  const toggleHeader = () => {
    if (currentScrollPosition > detachPoint) {
      header.classList.add('is-detached');

      if(isMobile && gallerySubHeader != null)
        gallerySubHeader.classList.add('is-detached');

    }

    if (currentScrollPosition > previousScrollPosition) {
      header.classList.add('is-not-visible');

      if(isMobile && gallerySubHeader != null)
        gallerySubHeader.classList.add('is-not-visible');
    } else {
      header.classList.remove('is-not-visible');

      if(isMobile && gallerySubHeader != null)
        gallerySubHeader.classList.remove('is-not-visible');

    }

  };
*/

  const storeHeaderHeight = () => {
    headerHeight = header.getBoundingClientRect().height;
  };

  const setupPaddingTop = () => {
    storeHeaderHeight();
    document.querySelector('body').style.paddingTop = `${headerHeight}px`;
  };

  //setupPaddingTop();

  // window.addEventListener('resize', setupPaddingTop);

  window.addEventListener('scroll', () => {

    currentScrollPosition = window.scrollY;

    const scrollDirection = currentScrollPosition - previousScrollPosition;
    const scrollDifference = Math.abs(currentScrollPosition - previousScrollPosition);

    if (!topOfPage()) {
      // hide menu bar when scrolling down
      if (scrollDirection > 0) {
        header.classList.add('is-not-visible');
        if(gallerySubHeader != null)
          gallerySubHeader.classList.add('is-not-visible');
      }
      else {
        header.classList.remove('is-not-visible');
        if(gallerySubHeader != null)
          gallerySubHeader.classList.remove('is-not-visible');
      }
    }
    else {
      header.classList.remove('is-not-visible');
      if(gallerySubHeader != null)
        gallerySubHeader.classList.remove('is-not-visible');
    }

    previousScrollPosition = currentScrollPosition;

  });

}



  /*
  // if user is at top of document, remove detachment
  if (topOfPage()) {
    header.classList.remove('is-detached');
    header.classList.remove('is-not-visible');

    if(isMobile && gallerySubHeader != null)
    {
      gallerySubHeader.classList.remove('is-detached');
      gallerySubHeader.classList.remove('is-not-visible');
    }

  }
  const scrollDifference = Math.abs(currentScrollPosition - previousScrollPosition);
  if (currentScrollPosition > headerOffset && scrollDifference >= toggleOffset) {
    toggleHeader();
  }

  if (bottomOfPage()) {
    header.classList.remove('is-not-visible');

    if(isMobile && gallerySubHeader != null)
      gallerySubHeader.classList.remove('is-not-visible');

  }

  previousScrollPosition = currentScrollPosition;
  */