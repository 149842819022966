import ModalVideo from 'modal-video';

const globalTreeCounter = document.querySelector('.js-global-tree-counter');
const globalTreeCounterMessage = document.querySelector('.js-global-tree-counter-message');

const toggleMessage = (event) => {
  event.stopPropagation();
  globalTreeCounterMessage.classList.toggle('is-closed');
  if (globalTreeCounterMessage.classList.contains('is-closed')) {
    document.removeEventListener('click', toggleMessage);
  } else {
    document.addEventListener('click', toggleMessage);
  }
};

if(globalTreeCounter !== null)
  globalTreeCounter.addEventListener('click', toggleMessage, false);

new ModalVideo('.youtube-modal');
