/* global siteurl */

import $ from 'jquery';

// nav dropdown menu
let isMobile = $(window).width() < 780;

if(!isMobile)
{
    // show dropdown
    $('.gallery-link, .c-nav-dropdown-gallery').mouseover(function () {
        $(".c-nav-dropdown-acrylic_glass").hide();
        $(".c-nav-dropdown-canvas").hide();
        $(".c-nav-dropdown-poster").hide();

        $(".c-nav-dropdown-gallery").show();
    });

    $('.poster-link, .c-nav-dropdown-posters').mouseover(function () {
        $(".c-nav-dropdown-gallery").hide();
        $(".c-nav-dropdown-acrylic_glass").hide();
        $(".c-nav-dropdown-canvas").hide();

        $(".c-nav-dropdown-poster").show();
    });

    $('.acrylic_glass-link, .c-nav-dropdown-acrylic_glass').mouseover(function () {
        $(".c-nav-dropdown-gallery").hide();
        $(".c-nav-dropdown-poster").hide();
        $(".c-nav-dropdown-canvas").hide();

        $(".c-nav-dropdown-acrylic_glass").show();
    });

    $('.canvas-link, .c-nav-dropdown-canvas').mouseover(function () {
        $(".c-nav-dropdown-gallery").hide();
        $(".c-nav-dropdown-poster").hide();
        $(".c-nav-dropdown-acrylic_glass").hide();

        $(".c-nav-dropdown-canvas").show();
    });

    $('.c-nav__item--calendars, .c-nav-dropdown-calendars').mouseover(function () {
        $(".c-nav-dropdown-calendars").show();
    });
    
    
    // hide dropdown:

    $('.c-main-header').mouseleave(function () {
        $(".c-nav-dropdown").hide();
    });
    
    let a = '.c-info-bar, .c-menu__logo, .c-nav__item--gift-cards, .c-nav__item--photolab, .c-nav__item--about-us, .c-menu__right';
    let b = '.c-nav__item--gallery, .c-nav__item--calendars, .c-nav__item--poster, .c-nav__item--acrylic_glass, .c-nav__item--canvas';

    $(a + ', .c-nav__item--calendars, .c-nav__item--poster, .c-nav__item--acrylic_glass, .c-nav__item--canvas').mouseover(function () {
        $(".c-nav-dropdown-gallery").hide();
    });

    $(a + ', .c-nav__item--gallery, .c-nav__item--calendars, .c-nav__item--acrylic_glass, .c-nav__item--canvas').mouseover(function () {
        $(".c-nav-dropdown-poster").hide();
    });

    $(a + ', .c-nav__item--gallery, .c-nav__item--calendars, .c-nav__item--poster, .c-nav__item--canvas').mouseover(function () {
        $(".c-nav-dropdown-acrylic_glass").hide();
    });

    $(a + ', .c-nav__item--gallery, .c-nav__item--calendars, .c-nav__item--poster, .c-nav__item--acrylic_glass').mouseover(function () {
        $(".c-nav-dropdown-canvas").hide();
    });



    $('.c-info-bar, .c-menu__logo, .gallery-link, .c-nav__item--photolab, .c-nav__item--gift-cards, .c-nav__item--about-us, .c-menu__right').mouseover(function () {
        $(".c-nav-dropdown-calendars").hide();
    });
}
